/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react';
import '@maptiler/sdk/dist/maptiler-sdk.css';
import './Map.css';
import * as maptilersdk from '@maptiler/sdk';

export default function Map({ tokaPorts, ugvPorts, km220Ports, uiId }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const lng = 31.223;
  const lat = 45.454;
  const zoom = 4;
  //todo extract to github secrets
  const API_KEY = 'fysAbJpnonqUYEipElqb';
  maptilersdk.config.apiKey = API_KEY;

  const [tokaMarkers] = useState([]);
  useEffect(() => {
    if (!map.current) {
      return;
    }

    updateMarkers(tokaPorts, tokaMarkers);
  }, [tokaPorts]);

  const [ugvMarkers] = useState([]);
  useEffect(() => {
    if (!map.current) {
      return;
    }

    // console.log(ugvPorts);

    updateMarkers(ugvPorts, ugvMarkers);
  }, [ugvPorts]);

  const [km220Markers] = useState([]);
  useEffect(() => {
    if (!map.current) {
      return;
    }

    // console.log(km220Ports);

    updateKm220Markers(km220Ports, km220Markers);
  }, [km220Ports]);

  useEffect(() => {
    if (map.current) {
      return;
    } // stops map from intializing more than once

    map.current = new maptilersdk.Map({
      container: mapContainer.current,
      style: maptilersdk.MapStyle.STREETS,
      // navigationControl: false,

      center: [lng, lat],
      zoom: zoom,
      // geolocate: maptilersdk.GeolocationType.POINT,

      // geolocateControl: false,
      // navigationControl: false,

      maptilerLogo: false,
      scaleControl: true,
    });
  }, []);

  function updateMarkers(ports, markers) {
    markers.forEach(function (marker) {
      marker.remove();
    });

    ports?.forEach(function (port) {
      var { marker, el } = drawMarkerWithLocation(port);

      el.style.backgroundImage = `url(/port.svg)`;
      el.addEventListener('click', function () {
        // ReactGA.event({
        //   action: port?.deviceId,
        // });
        window.open(port?.deviceId);
      });

      el.className = port?.name?.includes('IN_PROGRESS') ? 'blink_me' : null;

      markers.push(new maptilersdk.Marker({ element: el }).setLngLat(marker.geometry.coordinates).addTo(map.current));
    });

    // console.log(markers);
  }
  function updateKm220Markers(ports, markers) {
    ports?.forEach(function (port) {
      var { marker, el } = drawMarkerWithLocation(port);

      el.className = port?.job?.state === 'IN_PROGRESS' ? 'blink_me' : null;
      el.style.backgroundImage = port.online ? `url(/port.svg)` : `url(/portOffline.svg)`;
      el.addEventListener('click', function () {
        window.open(`/start?station=${port.number}`);
      });
      if (port?.name?.includes('inService')) {
        el.style.backgroundImage = `url(/inService.svg)`;
        return;
      }

      if (port?.name.includes('viwatt-')) {
        el.style.width = '20px';
        el.style.height = '20px';
        el.style.backgroundImage = `url(/port20.svg)`;
        el.addEventListener('click', function () {
          window.open(port?.deviceId);
        });
      }

      markers.push(new maptilersdk.Marker({ element: el }).setLngLat(marker.geometry.coordinates).addTo(map.current));
    });
  }
  function drawMarkerWithLocation(port) {
    var marker = {
      type: 'Feature',
      properties: {
        message: 'Baz',
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(`${port.longitude}`), parseFloat(`${port.latitude}`)],
      },
    };

    var el = document.createElement('div');
    el.style.width = '30px';
    el.style.height = '30px';
    return { marker, el };
  }

  function isIos() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on isIos 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}
