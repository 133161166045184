// https://products.aspose.app/words/picresize/svg
export default function SVGACFilter() {
  return (
    <svg width="193" height="117" viewBox="0 0 193 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_2099_111)">
        <rect x="22" y="22" width="149" height="73" rx="14" fill="#2C2C2C" />
        <rect
          x="24.5"
          y="24.5"
          width="144"
          height="68"
          rx="11.5"
          stroke="url(#paint0_linear_2099_111)"
          strokeWidth="5"
        />
        <rect
          x="24.5"
          y="24.5"
          width="144"
          height="68"
          rx="11.5"
          stroke="url(#paint1_linear_2099_111)"
          strokeWidth="5"
        />
      </g>
      <g filter="url(#filter1_dii_2099_111)">
        <path
          d="M39.2344 69.8164L49.6708 46.4014H55.0228L65.4926 69.8164H59.8061L51.2429 49.1443H53.3837L44.7871 69.8164H39.2344ZM44.4526 64.7989L45.8909 60.6846H57.9329L59.4047 64.7989H44.4526ZM79.8045 70.2178C77.9982 70.2178 76.3146 69.9279 74.7536 69.3481C73.2149 68.746 71.8769 67.8986 70.7396 66.8059C69.6023 65.7132 68.7103 64.431 68.0636 62.9592C67.4392 61.4874 67.127 59.8706 67.127 58.1089C67.127 56.3472 67.4392 54.7304 68.0636 53.2586C68.7103 51.7868 69.6023 50.5046 70.7396 49.4119C71.8992 48.3192 73.2483 47.4829 74.787 46.9031C76.3257 46.301 78.0094 46 79.838 46C81.8673 46 83.6959 46.3568 85.3238 47.0704C86.974 47.7617 88.3566 48.7875 89.4716 50.1478L85.9928 53.359C85.19 52.4447 84.298 51.7645 83.3168 51.3185C82.3356 50.8503 81.2652 50.6161 80.1056 50.6161C79.0129 50.6161 78.0094 50.7945 77.0951 51.1513C76.1808 51.5081 75.3891 52.021 74.7201 52.69C74.0511 53.359 73.5271 54.1506 73.148 55.065C72.7912 55.9793 72.6128 56.9939 72.6128 58.1089C72.6128 59.2239 72.7912 60.2386 73.148 61.1529C73.5271 62.0672 74.0511 62.8588 74.7201 63.5278C75.3891 64.1968 76.1808 64.7097 77.0951 65.0665C78.0094 65.4233 79.0129 65.6017 80.1056 65.6017C81.2652 65.6017 82.3356 65.3787 83.3168 64.9327C84.298 64.4644 85.19 63.762 85.9928 62.8254L89.4716 66.0366C88.3566 67.3969 86.974 68.4338 85.3238 69.1474C83.6959 69.861 81.8561 70.2178 79.8045 70.2178Z"
          fill="url(#paint2_linear_2099_111)"
          fillOpacity="0.99"
        />
        <path
          d="M39.2344 69.8164L49.6708 46.4014H55.0228L65.4926 69.8164H59.8061L51.2429 49.1443H53.3837L44.7871 69.8164H39.2344ZM44.4526 64.7989L45.8909 60.6846H57.9329L59.4047 64.7989H44.4526ZM79.8045 70.2178C77.9982 70.2178 76.3146 69.9279 74.7536 69.3481C73.2149 68.746 71.8769 67.8986 70.7396 66.8059C69.6023 65.7132 68.7103 64.431 68.0636 62.9592C67.4392 61.4874 67.127 59.8706 67.127 58.1089C67.127 56.3472 67.4392 54.7304 68.0636 53.2586C68.7103 51.7868 69.6023 50.5046 70.7396 49.4119C71.8992 48.3192 73.2483 47.4829 74.787 46.9031C76.3257 46.301 78.0094 46 79.838 46C81.8673 46 83.6959 46.3568 85.3238 47.0704C86.974 47.7617 88.3566 48.7875 89.4716 50.1478L85.9928 53.359C85.19 52.4447 84.298 51.7645 83.3168 51.3185C82.3356 50.8503 81.2652 50.6161 80.1056 50.6161C79.0129 50.6161 78.0094 50.7945 77.0951 51.1513C76.1808 51.5081 75.3891 52.021 74.7201 52.69C74.0511 53.359 73.5271 54.1506 73.148 55.065C72.7912 55.9793 72.6128 56.9939 72.6128 58.1089C72.6128 59.2239 72.7912 60.2386 73.148 61.1529C73.5271 62.0672 74.0511 62.8588 74.7201 63.5278C75.3891 64.1968 76.1808 64.7097 77.0951 65.0665C78.0094 65.4233 79.0129 65.6017 80.1056 65.6017C81.2652 65.6017 82.3356 65.3787 83.3168 64.9327C84.298 64.4644 85.19 63.762 85.9928 62.8254L89.4716 66.0366C88.3566 67.3969 86.974 68.4338 85.3238 69.1474C83.6959 69.861 81.8561 70.2178 79.8045 70.2178Z"
          fill="#FC019B"
        />
        <path
          d="M39.2344 69.8164L49.6708 46.4014H55.0228L65.4926 69.8164H59.8061L51.2429 49.1443H53.3837L44.7871 69.8164H39.2344ZM44.4526 64.7989L45.8909 60.6846H57.9329L59.4047 64.7989H44.4526ZM79.8045 70.2178C77.9982 70.2178 76.3146 69.9279 74.7536 69.3481C73.2149 68.746 71.8769 67.8986 70.7396 66.8059C69.6023 65.7132 68.7103 64.431 68.0636 62.9592C67.4392 61.4874 67.127 59.8706 67.127 58.1089C67.127 56.3472 67.4392 54.7304 68.0636 53.2586C68.7103 51.7868 69.6023 50.5046 70.7396 49.4119C71.8992 48.3192 73.2483 47.4829 74.787 46.9031C76.3257 46.301 78.0094 46 79.838 46C81.8673 46 83.6959 46.3568 85.3238 47.0704C86.974 47.7617 88.3566 48.7875 89.4716 50.1478L85.9928 53.359C85.19 52.4447 84.298 51.7645 83.3168 51.3185C82.3356 50.8503 81.2652 50.6161 80.1056 50.6161C79.0129 50.6161 78.0094 50.7945 77.0951 51.1513C76.1808 51.5081 75.3891 52.021 74.7201 52.69C74.0511 53.359 73.5271 54.1506 73.148 55.065C72.7912 55.9793 72.6128 56.9939 72.6128 58.1089C72.6128 59.2239 72.7912 60.2386 73.148 61.1529C73.5271 62.0672 74.0511 62.8588 74.7201 63.5278C75.3891 64.1968 76.1808 64.7097 77.0951 65.0665C78.0094 65.4233 79.0129 65.6017 80.1056 65.6017C81.2652 65.6017 82.3356 65.3787 83.3168 64.9327C84.298 64.4644 85.19 63.762 85.9928 62.8254L89.4716 66.0366C88.3566 67.3969 86.974 68.4338 85.3238 69.1474C83.6959 69.861 81.8561 70.2178 79.8045 70.2178Z"
          fill="url(#paint3_linear_2099_111)"
          fillOpacity="0.21"
        />
      </g>
      <path
        d="M104 69.8164V46.4014H114.637C117.179 46.4014 119.42 46.892 121.361 47.8732C123.301 48.8321 124.817 50.1812 125.91 51.9206C127.002 53.66 127.549 55.7228 127.549 58.1089C127.549 60.4727 127.002 62.5354 125.91 64.2972C124.817 66.0366 123.301 67.3969 121.361 68.3781C119.42 69.337 117.179 69.8164 114.637 69.8164H104ZM109.419 65.3676H114.37C115.931 65.3676 117.28 65.0777 118.417 64.4979C119.577 63.8958 120.469 63.0484 121.093 61.9556C121.74 60.8629 122.063 59.5807 122.063 58.1089C122.063 56.6148 121.74 55.3325 121.093 54.2621C120.469 53.1694 119.577 52.3332 118.417 51.7534C117.28 51.1513 115.931 50.8502 114.37 50.8502H109.419V65.3676ZM143.845 70.2178C142.038 70.2178 140.355 69.9279 138.794 69.3481C137.255 68.746 135.917 67.8986 134.78 66.8059C133.642 65.7132 132.75 64.4309 132.104 62.9591C131.479 61.4873 131.167 59.8706 131.167 58.1089C131.167 56.3472 131.479 54.7304 132.104 53.2586C132.75 51.7868 133.642 50.5046 134.78 49.4119C135.939 48.3192 137.289 47.4829 138.827 46.9031C140.366 46.301 142.05 46 143.878 46C145.907 46 147.736 46.3568 149.364 47.0704C151.014 47.7617 152.397 48.7875 153.512 50.1478L150.033 53.359C149.23 52.4447 148.338 51.7645 147.357 51.3185C146.376 50.8502 145.305 50.6161 144.146 50.6161C143.053 50.6161 142.05 50.7945 141.135 51.1513C140.221 51.5081 139.429 52.021 138.76 52.69C138.091 53.359 137.567 54.1506 137.188 55.0649C136.831 55.9792 136.653 56.9939 136.653 58.1089C136.653 59.2239 136.831 60.2386 137.188 61.1529C137.567 62.0672 138.091 62.8588 138.76 63.5278C139.429 64.1968 140.221 64.7097 141.135 65.0665C142.05 65.4233 143.053 65.6017 144.146 65.6017C145.305 65.6017 146.376 65.3787 147.357 64.9327C148.338 64.4644 149.23 63.762 150.033 62.8254L153.512 66.0366C152.397 67.3969 151.014 68.4338 149.364 69.1474C147.736 69.861 145.896 70.2178 143.845 70.2178Z"
        fill="url(#paint4_linear_2099_111)"
        fillOpacity="0.09"
      />
      <path
        d="M104 69.8164V46.4014H114.637C117.179 46.4014 119.42 46.892 121.361 47.8732C123.301 48.8321 124.817 50.1812 125.91 51.9206C127.002 53.66 127.549 55.7228 127.549 58.1089C127.549 60.4727 127.002 62.5354 125.91 64.2972C124.817 66.0366 123.301 67.3969 121.361 68.3781C119.42 69.337 117.179 69.8164 114.637 69.8164H104ZM109.419 65.3676H114.37C115.931 65.3676 117.28 65.0777 118.417 64.4979C119.577 63.8958 120.469 63.0484 121.093 61.9556C121.74 60.8629 122.063 59.5807 122.063 58.1089C122.063 56.6148 121.74 55.3325 121.093 54.2621C120.469 53.1694 119.577 52.3332 118.417 51.7534C117.28 51.1513 115.931 50.8502 114.37 50.8502H109.419V65.3676ZM143.845 70.2178C142.038 70.2178 140.355 69.9279 138.794 69.3481C137.255 68.746 135.917 67.8986 134.78 66.8059C133.642 65.7132 132.75 64.4309 132.104 62.9591C131.479 61.4873 131.167 59.8706 131.167 58.1089C131.167 56.3472 131.479 54.7304 132.104 53.2586C132.75 51.7868 133.642 50.5046 134.78 49.4119C135.939 48.3192 137.289 47.4829 138.827 46.9031C140.366 46.301 142.05 46 143.878 46C145.907 46 147.736 46.3568 149.364 47.0704C151.014 47.7617 152.397 48.7875 153.512 50.1478L150.033 53.359C149.23 52.4447 148.338 51.7645 147.357 51.3185C146.376 50.8502 145.305 50.6161 144.146 50.6161C143.053 50.6161 142.05 50.7945 141.135 51.1513C140.221 51.5081 139.429 52.021 138.76 52.69C138.091 53.359 137.567 54.1506 137.188 55.0649C136.831 55.9792 136.653 56.9939 136.653 58.1089C136.653 59.2239 136.831 60.2386 137.188 61.1529C137.567 62.0672 138.091 62.8588 138.76 63.5278C139.429 64.1968 140.221 64.7097 141.135 65.0665C142.05 65.4233 143.053 65.6017 144.146 65.6017C145.305 65.6017 146.376 65.3787 147.357 64.9327C148.338 64.4644 149.23 63.762 150.033 62.8254L153.512 66.0366C152.397 67.3969 151.014 68.4338 149.364 69.1474C147.736 69.861 145.896 70.2178 143.845 70.2178Z"
        fill="#FC019B"
        fillOpacity="0.34"
      />
      <path
        d="M104 69.8164V46.4014H114.637C117.179 46.4014 119.42 46.892 121.361 47.8732C123.301 48.8321 124.817 50.1812 125.91 51.9206C127.002 53.66 127.549 55.7228 127.549 58.1089C127.549 60.4727 127.002 62.5354 125.91 64.2972C124.817 66.0366 123.301 67.3969 121.361 68.3781C119.42 69.337 117.179 69.8164 114.637 69.8164H104ZM109.419 65.3676H114.37C115.931 65.3676 117.28 65.0777 118.417 64.4979C119.577 63.8958 120.469 63.0484 121.093 61.9556C121.74 60.8629 122.063 59.5807 122.063 58.1089C122.063 56.6148 121.74 55.3325 121.093 54.2621C120.469 53.1694 119.577 52.3332 118.417 51.7534C117.28 51.1513 115.931 50.8502 114.37 50.8502H109.419V65.3676ZM143.845 70.2178C142.038 70.2178 140.355 69.9279 138.794 69.3481C137.255 68.746 135.917 67.8986 134.78 66.8059C133.642 65.7132 132.75 64.4309 132.104 62.9591C131.479 61.4873 131.167 59.8706 131.167 58.1089C131.167 56.3472 131.479 54.7304 132.104 53.2586C132.75 51.7868 133.642 50.5046 134.78 49.4119C135.939 48.3192 137.289 47.4829 138.827 46.9031C140.366 46.301 142.05 46 143.878 46C145.907 46 147.736 46.3568 149.364 47.0704C151.014 47.7617 152.397 48.7875 153.512 50.1478L150.033 53.359C149.23 52.4447 148.338 51.7645 147.357 51.3185C146.376 50.8502 145.305 50.6161 144.146 50.6161C143.053 50.6161 142.05 50.7945 141.135 51.1513C140.221 51.5081 139.429 52.021 138.76 52.69C138.091 53.359 137.567 54.1506 137.188 55.0649C136.831 55.9792 136.653 56.9939 136.653 58.1089C136.653 59.2239 136.831 60.2386 137.188 61.1529C137.567 62.0672 138.091 62.8588 138.76 63.5278C139.429 64.1968 140.221 64.7097 141.135 65.0665C142.05 65.4233 143.053 65.6017 144.146 65.6017C145.305 65.6017 146.376 65.3787 147.357 64.9327C148.338 64.4644 149.23 63.762 150.033 62.8254L153.512 66.0366C152.397 67.3969 151.014 68.4338 149.364 69.1474C147.736 69.861 145.896 70.2178 143.845 70.2178Z"
        fill="url(#paint5_linear_2099_111)"
        fillOpacity="0.35"
      />
      <defs>
        <filter
          id="filter0_dd_2099_111"
          x="0"
          y="0"
          width="193"
          height="117"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.578144 0 0 0 0 0.0642723 0 0 0 0 0.838333 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2099_111" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0.239216 0 0 0 0 0.772549 0 0 0 0.99 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2099_111" result="effect2_dropShadow_2099_111" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2099_111" result="shape" />
        </filter>
        <filter
          id="filter1_dii_2099_111"
          x="30.6923"
          y="37.4579"
          width="67.3214"
          height="41.3019"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.27103" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0 0 0 0 0 0.909804 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2099_111" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2099_111" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.727045" dy="-1.45409" />
          <feGaussianBlur stdDeviation="3.70793" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.784314 0 0 0 0 0 0 0 0 0 0.909804 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2099_111" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.727045" />
          <feGaussianBlur stdDeviation="0.254466" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_2099_111" result="effect3_innerShadow_2099_111" />
        </filter>
        <linearGradient
          id="paint0_linear_2099_111"
          x1="52.3518"
          y1="30.1111"
          x2="84.765"
          y2="109.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#541291" />
          <stop offset="1" stopColor="#970291" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2099_111"
          x1="52.3518"
          y1="30.1111"
          x2="84.765"
          y2="109.37"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E00D4" />
          <stop offset="1" stopColor="#C100B9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2099_111"
          x1="124.012"
          y1="57.1787"
          x2="124.012"
          y2="85.7586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C83DC5" />
          <stop offset="1" stopColor="#932D90" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2099_111"
          x1="124.012"
          y1="57.1787"
          x2="124.012"
          y2="85.7586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA4242" stopOpacity="0" />
          <stop offset="0.514" stopColor="#FF0000" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2099_111"
          x1="186.001"
          y1="56.9951"
          x2="186.001"
          y2="85.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C83DC5" />
          <stop offset="1" stopColor="#932D90" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2099_111"
          x1="186.001"
          y1="56.9951"
          x2="186.001"
          y2="85.575"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA4242" stopOpacity="0" />
          <stop offset="0.514" stopColor="#FF0000" />
        </linearGradient>
      </defs>
    </svg>
  );
}
