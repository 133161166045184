/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './startPage.css';
import '../../components/buttons/mainBtn.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ErrorPage from '../errorPage/ErrorPage';
import { Row } from 'react-bootstrap';
import { WebCamera } from '../../components/webCamera/WebCamera';
import { startCharging } from './startCharging';
import DelayedStartModal from './DelayedStartModal';
import PayOrStartModal from './PayOrStartModal';
import { api } from '../../redux/api';
import { useDispatch } from 'react-redux';
import { isWorkingTime, plugTypeMaping } from '../../utils/utils';
import ModalContacts from '../contactsPage/modal/ModalContacts';

import { Button, Card, Slider } from '@ui';
import Box from '@mui/material/Box';

export default function StartPage() {
  const { station, stationNumber, openFrom, closedFrom, isTwentyFourHour } = useOutletContext();
  const INITIAL_SLIDER_KWH = 15;
  const FULL_CHARGE_FIX_PRICE = 500;
  const FULL_CHARGE_KWH = Math.trunc(FULL_CHARGE_FIX_PRICE / station?.costPerKwt / 100);

  const CUSTOM_CHARGE_MIN = 5;
  const CUSTOM_CHARGE_STEP = 5;
  const CUSTOM_CHARGE_MAX = 80;

  const [modalOpen, setModalOpen] = useState(false);
  const [kwt, setKwt] = useState(INITIAL_SLIDER_KWH);
  const [error, setError] = useState(null);
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [startFreeStatus] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [customChargeValue, setCustomChargeValue] = useState(kwt);

  const autoStart = false;

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (startFreeStatus === 'success') {
      dispatch(api?.util.upsertQueryData('getStationStatus', { stationNumber }, {}));
      navigate(`/charging?station=${stationNumber}`);
    }
  }, [startFreeStatus]);

  useEffect(() => {
    const lastKwt = localStorage.getItem('lastKwt');
    if (lastKwt === null) {
      localStorage.setItem('lastKwt', String(INITIAL_SLIDER_KWH));
    } else {
      setKwt(Number(lastKwt));
      setCustomChargeValue(Number(lastKwt));
    }
  }, []);

  const dispatch = useDispatch();

  const { openPaymentLink } = startCharging();
  const isWorking = isWorkingTime(station, openFrom, closedFrom, isTwentyFourHour);

  const { t } = useTranslation();

  function payAndCharge(amount) {
    localStorage.setItem('lastKwt', String(amount));
    setNoticeModalOpen(true);

    // if (!isWorking) {
    //   setModalOpen(true);
    // } else {
    //   getAndSetInvoiceIdWithPaymentLink(stationNumber, amount, autoStart, setError);
    //   ReactGA.event({
    //     category: `start_${amount}_kWt`,
    //     action: `start_${amount}_kWt`,
    //   });
    // }
  }

  function mainButtonText() {
    return `${parseFloat((customChargeValue * station?.costPerKwt) / 100).toFixed(0)} ${t('currency')} (${customChargeValue} ${t('powerWt')})`;
  }

  if (error) {
    return (
      <ErrorPage
        errorHeader={<Trans i18nKey="errorOfflineHeader">Port #{{ stationNumber }} is offline :(</Trans>}
        errorBody={t('errorOfflineBody')}
      />
    );
  }
  // const stationMapLink = `https://www.google.com/maps/dir//${station?.latitude},${station?.longitude}/@${station?.latitude},${station?.longitude},12z`;

  const payAndChargeForStation02 = () => {
    if (Number(stationNumber) === 2) return;
    payAndCharge(customChargeValue);
  };

  return (
    <div>
      <div id="startPage">
        <Box sx={{ m: '10% 7%' }}>
          <Card
            variant="invert"
            style={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBlock: '10px',
            }}
          >
            <span
              style={{
                fontSize: '38px',
                letterSpacing: '2px',
              }}
            >
              {t('station')}{' '}
              <span
                style={{
                  fontWeight: '700',
                  marginInline: '0.3rem',
                }}
              >
                {stationNumber}
              </span>{' '}
              <span style={{ marginInline: '0.3rem' }}>{'>> '}</span>
              {plugTypeMaping[station?.plugType]}
            </span>
          </Card>

          {!isTwentyFourHour && (
            <Row id="startPageTitleBox" className="justify-content-center mt-3 mb-3 pr-1 pl-1">
              <p className="delayedStartTime">
                <Trans i18nKey={'delayedInfo'}>
                  Порт працює з {{ openFrom }} до
                  {{ closedFrom }}
                </Trans>
              </p>
            </Row>
          )}

          <Box width="100%" display="flex" justifyContent="center" alignItems="center" position="relative">
            <span
              style={{
                fontSize: '28px',
                letterSpacing: '1.6px',
                marginBlock: '10px',
              }}
            >
              <span style={{ fontWeight: '700' }}>{station.costPerKwt / 100}</span>
              {t('costPerKwtAlt')}
            </span>
          </Box>

          <Box sx={{ m: '40px 0' }}>
            <Card style={{ padding: '30px 0' }}>
              <Card variant="invert">
                <Button onClick={() => payAndCharge(customChargeValue)}>
                  <span
                    style={{
                      fontSize: '26px',
                      letterSpacing: '4.3px',
                    }}
                  >
                    {mainButtonText()}
                  </span>
                </Button>
              </Card>

              <Slider
                min={CUSTOM_CHARGE_MIN}
                step={CUSTOM_CHARGE_STEP}
                max={CUSTOM_CHARGE_MAX}
                value={customChargeValue}
                text={t('chooseVolume')}
                onChange={(event, value) => {
                  setCustomChargeValue(value);
                  setKwt(value);
                }}
                onTouchEnd={payAndChargeForStation02}
                style={{ width: '90%', marginTop: '22px' }}
              />
            </Card>
          </Box>
          <br />
          <Button
            variant="outline"
            style={{
              display: 'flex',
              gap: '6px',
              margin: 'auto',
              width: '86%',
            }}
            onClick={() => setIsOpenModal(true)}
          >
            {/*<Icon i="phone" size="32px" />*/}
            <span
              style={{
                fontSize: '24px',
                fontWeight: '300',
                letterSpacing: '2px',
              }}
            >
              {t('contactUs')}
            </span>
          </Button>

          {station?.plugshareLink && (
            <Button
              link={station?.plugshareLink}
              variant="outline"
              style={{
                display: 'flex',
                gap: '12px',
                margin: '45px auto',
                width: '86%',
              }}
            >
              {/*<Icon i="feedback" size="36px" />*/}
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: '300',
                  letterSpacing: '2px',
                }}
              >
                {t('photo')}
              </span>
            </Button>
          )}

          <Button
            link={`https://www.google.com/maps/dir//${station.latitude},${station.longitude}/@${station.latitude},${station.longitude},12z`}
            variant="outline"
            style={{
              display: 'flex',
              gap: '12px',
              margin: '45px auto',
              width: '86%',
            }}
          >
            {/*<Icon i="feedback" size="36px" />*/}
            <span
              style={{
                fontSize: '24px',
                fontWeight: '300',
                letterSpacing: '2px',
              }}
            >
              {t('route')}
            </span>
          </Button>
        </Box>
      </div>

      {isOpenModal && <ModalContacts setModalOpen={setIsOpenModal} modalOpen={isOpenModal} />}
      <DelayedStartModal
        stationNumber={stationNumber}
        openPaymentLink={openPaymentLink}
        setError={setError}
        kwt={kwt}
        openFrom={openFrom}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        autoStart={autoStart}
      />
      <PayOrStartModal
        stationNumber={stationNumber}
        kwt={kwt}
        costPerKwt={station?.costPerKwt}
        modalOpen={noticeModalOpen}
        setModalOpen={setNoticeModalOpen}
        nextModalOpen={setModalOpen}
        isTwentyFourHour={isTwentyFourHour}
        openPaymentLink={openPaymentLink}
        setError={setError}
        FULL_CHARGE_KWH={FULL_CHARGE_KWH}
        autoStart={autoStart}
      />

      {station?.webcamUrl && <WebCamera stationNumber={stationNumber} webcamUrl={station?.webcamUrl} />}
    </div>
  );
}
